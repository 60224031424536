<template>
  <v-row justify="center" class="text-center">
    <v-col cols="12" sm="10">
      <v-card width="100%" class="main_card pa-lg-15" flat rounded="xl">
        <v-row class="justify-center">
          <v-col cols="12" sm="7" class="pt-lg-12 pl-lg-12">
            <v-card-title class="text-left font2 font_wrap">
              Find new projects /<br />workoplaces / contracts with no worries
            </v-card-title>
            <v-card-subtitle
              class="text-left subtitle1 font1 mt-sm-4 pr-12 font_wrap"
            >
              We’ve designed and created our service to help people all around
              the IT world. We analyze, help with finding a workplace, help with
              recruitment and not only!
            </v-card-subtitle>
            <v-card-actions class="text-left pa-4 d-none d-md-flex">
              <v-btn
                class="button_start px-8 text-capitalize font1"
                large
                color="#4024EA"
              >
                Let’s start!
              </v-btn>
            </v-card-actions>
          </v-col>
          <v-col cols="8" sm="4" offset-md="1">
            <v-img
              :src="require('../../assets/HomeForDevelopers/1.png')"
            ></v-img>
          </v-col>
          <v-col cols="12" class="d-flex d-md-none px-8 py-4">
            <v-btn
              class="button_start px-8 text-capitalize font1"
              large
              elevation="0"
              block
              color="#4024EA"
            >
              Let’s start!
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "FindNewProject",
  data: () => ({}),
};
</script>
<style scoped>
.button_start {
  color: #fff;
  border-radius: 10px;
}
.font2 {
  font-family: "Poppins", sans-serif !important;
  font-weight: 700 !important;
  font-size: 2.2rem;
  line-height: 3.8rem;
  color: #2b2b2b;
}
.main_card {
  background: rgb(231, 238, 245, 0.3);
}
.subtitle1 {
  font-size: 1.1rem;
  line-height: 2rem;
  color: #565656;
}
@media screen and (max-width: 400px) {
  .font2 {
    font-size: 1.1rem;
    line-height: 1.9rem;
  }
  .subtitle1 {
    font-size: 0.8rem;
    line-height: 1.2rem;
    color: #565656;
  }
  .button_start {
    height: 36px !important;
    font-size: 12px;
  }
}
</style>