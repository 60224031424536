<template>
  <v-row justify="center" class="text-center">
    <v-col cols="10">
      <v-row justify="center" class="text-left">
        <v-col cols="12" md="5" class="pr-md-12 pt-12">
          <div class="font2">the web-application itself</div>
          <hr class="hr my-8" />
          <div class="subtitle mr-md-12">
            Service purpose is to transform our Human Resources practical
            knowledge to an advanced , easy-to-use service that will help
            thousands of specialists and HR’s around the world.
          </div>
          <v-row justify="end" class="mt-4 mb-6">
            <svg
              width="36"
              height="27"
              viewBox="0 0 36 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.24 26.984L6.616 9L8.728 17.064C6.25333 17.064 4.22667 16.36 2.648 14.952C1.06933 13.5013 0.28 11.5387 0.28 9.064C0.28 6.54667 1.06933 4.56267 2.648 3.112C4.26933 1.61867 6.296 0.871998 8.728 0.871998C11.16 0.871998 13.1653 1.61867 14.744 3.112C16.3653 4.56267 17.176 6.54667 17.176 9.064C17.176 9.74666 17.112 10.4293 16.984 11.112C16.8987 11.7947 16.6853 12.6267 16.344 13.608C16.0027 14.5467 15.448 15.8267 14.68 17.448L10.2 26.984H1.24ZM19.8 26.984L25.176 9L27.288 17.064C24.8133 17.064 22.7867 16.36 21.208 14.952C19.6293 13.5013 18.84 11.5387 18.84 9.064C18.84 6.54667 19.6293 4.56267 21.208 3.112C22.8293 1.61867 24.856 0.871998 27.288 0.871998C29.72 0.871998 31.7253 1.61867 33.304 3.112C34.9253 4.56267 35.736 6.54667 35.736 9.064C35.736 9.74666 35.672 10.4293 35.544 11.112C35.4587 11.7947 35.2453 12.6267 34.904 13.608C34.5627 14.5467 34.008 15.8267 33.24 17.448L28.76 26.984H19.8Z"
                fill="#4024EA"
              />
            </svg>
          </v-row>
          <div class="subtitle mr-md-12">
            As a result, our passion to IT market and searching for a universal
            job tool helped us to create it
          </div>
        </v-col>
        <v-col cols="12" md="7" class="pl-md-12 mt-12 mt-sm-0">
          <v-card class="card_main" flat>
            <v-row justify="center" class="ma-0">
              <v-col cols="12" sm="6" class="py-10 pa-sm-12 br bb">
                <v-row>
                  <v-col cols="2" class="pr-0 text-right">
                    <svg
                      width="17"
                      height="25"
                      viewBox="0 0 17 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="2"
                        y="2"
                        width="13.3333"
                        height="21.3333"
                        rx="1"
                        stroke="#4024EA"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M7.33333 3.33329H10"
                        stroke="#4024EA"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8.66667 19.3333V19.3467"
                        stroke="#4024EA"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </v-col>
                  <v-col cols="10">
                    <div class="card_title font1 mb-3">
                      Avilable on any gadget
                    </div>
                    <div class="card_subtitle font1">
                      Use our platform on any gadget you have. Be productive
                      eveywhere
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" sm="6" class="py-10 pa-sm-12 bb">
                <v-row>
                  <v-col cols="2" class="pr-0 text-right">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8 13V4.5C8 3.67157 8.67157 3 9.5 3C10.3284 3 11 3.67157 11 4.5V12"
                        stroke="#4024EA"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M11 11.5V9.5C11 8.67157 11.6716 8 12.5 8C13.3284 8 14 8.67157 14 9.5V12"
                        stroke="#4024EA"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M14 10.5C14 9.67157 14.6716 9 15.5 9C16.3284 9 17 9.67157 17 10.5V12"
                        stroke="#4024EA"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M17 11.5C17 10.6716 17.6716 10 18.5 10C19.3284 10 20 10.6716 20 11.5V16C20 19.3137 17.3137 22 14 22H12H12.208C10.1895 22.0003 8.30617 20.9858 7.19601 19.3L7.00001 19C6.68801 18.521 5.59301 16.612 3.71401 13.272C3.31571 12.5641 3.55333 11.6677 4.25001 11.25C4.98429 10.8094 5.92416 10.9248 6.53001 11.53L8.00001 13"
                        stroke="#4024EA"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M5 3L4 2"
                        stroke="#4024EA"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M4 7H3"
                        stroke="#4024EA"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M14 3L15 2"
                        stroke="#4024EA"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M15 6H16"
                        stroke="#4024EA"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </v-col>
                  <v-col cols="10">
                    <div class="card_title font1 mb-3">Free to start trial</div>
                    <div class="card_subtitle font1">
                      Just register, fill in and start working for free as a
                      specialist
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" sm="6" class="py-10 pa-sm-12 br bb">
                <v-row>
                  <v-col cols="2" class="pr-0 text-right">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="9"
                        cy="7"
                        r="4"
                        stroke="#4024EA"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M3 21V19C3 16.7909 4.79086 15 7 15H11C13.2091 15 15 16.7909 15 19V21"
                        stroke="#4024EA"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M16 11L18 13L22 9"
                        stroke="#4024EA"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </v-col>
                  <v-col cols="10">
                    <div class="card_title font1 mb-3">Only approved users</div>
                    <div class="card_subtitle font1">
                      We check everyone who enters our system manually.
                      Truthworthy is important for us.
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" sm="6" class="py-10 pa-sm-12 bb">
                <v-row>
                  <v-col cols="2" class="pr-0 text-right">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 21L21 6L18 3L3 18L6 21"
                        stroke="#4024EA"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M15 6L18 9"
                        stroke="#4024EA"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M9 3C9 4.10457 9.89543 5 11 5C9.89543 5 9 5.89543 9 7C9 5.89543 8.10457 5 7 5C8.10457 5 9 4.10457 9 3"
                        stroke="#4024EA"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M19 13C19 14.1046 19.8954 15 21 15C19.8954 15 19 15.8954 19 17C19 15.8954 18.1046 15 17 15C18.1046 15 19 14.1046 19 13"
                        stroke="#4024EA"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </v-col>
                  <v-col cols="10">
                    <div class="card_title font1 mb-3">
                      Quick to find right contractor
                    </div>
                    <div class="card_subtitle font1">
                      With our platform hiring or job finding process never was
                      easier.
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" sm="6" class="py-10 pa-sm-12 br bb_xs">
                <v-row>
                  <v-col cols="2" class="pr-0 text-right">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="9"
                        cy="7"
                        r="4"
                        stroke="#4024EA"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M3 21V19C3 16.7909 4.79086 15 7 15H11C13.2091 15 15 16.7909 15 19V21"
                        stroke="#4024EA"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M16 3.13C17.7699 3.58317 19.0078 5.178 19.0078 7.005C19.0078 8.83201 17.7699 10.4268 16 10.88"
                        stroke="#4024EA"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M21 21V19C20.9896 17.1846 19.7578 15.6039 18 15.15"
                        stroke="#4024EA"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </v-col>
                  <v-col cols="10">
                    <div class="card_title font1 mb-3">Teams maker</div>
                    <div class="card_subtitle font1">
                      Need more than a one contractor? Hire the whole team!
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" sm="6" class="py-10 pa-sm-12">
                <v-row>
                  <v-col cols="2" class="pr-0 text-right">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="10"
                        cy="10"
                        r="9"
                        stroke="#4024EA"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M1.60001 7H18.4"
                        stroke="#4024EA"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M1.60001 13H18.4"
                        stroke="#4024EA"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M9.50001 1C6.06295 6.50778 6.06295 13.4922 9.50001 19"
                        stroke="#4024EA"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.5 1C13.9371 6.50778 13.9371 13.4922 10.5 19"
                        stroke="#4024EA"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </v-col>
                  <v-col cols="10">
                    <div class="card_title font1 mb-3">
                      Hire and work worldwide
                    </div>
                    <div class="card_subtitle font1">
                      Even if you are new in IT services, our system is super
                      easy to use!
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {};
</script>
<style scoped>
.bb {
  border-bottom: 4px solid rgba(219, 218, 239, 0.25);
}
.br {
  border-right: 4px solid rgba(219, 218, 239, 0.25);
}
.card_subtitle {
  color: #dbdaef;
  font-weight: 500 !important;
}
.card_title {
  color: #e9e9e9;
  font-size: 1.1rem;
}
.card_main {
  border: 3px solid #4024ea;
  background: transparent;
  border-radius: 22px;
}
.subtitle {
  color: rgba(233, 233, 233, 0.65);
  font-size: 1.1rem;
  font-weight: 600;
}
.hr {
  border: unset;
  height: 6px;
  background: #4024ea;
  border-radius: 10px;
  width: 20%;
}
.font2 {
  font-family: "Poppins", sans-serif !important;
  font-weight: 700 !important;
  font-size: 2rem;
  line-height: 3rem;
  color: #e9e9e9;
}
@media screen and (max-width: 400px) {
  .bb_xs {
    border-bottom: 4px solid rgba(219, 218, 239, 0.25);
  }
  .br {
    border-right: unset !important;
  }
  .card_subtitle {
    font-size: 0.85em;
  }
  .card_title {
    color: #e9e9e9;
    font-size: 1rem;
  }
  .font2 {
    font-size: 1.1rem;
    line-height: 3rem;
  }
  .subtitle {
    font-size: 1rem;
  }
}
</style>