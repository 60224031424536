<template>
  <v-row justify="center" class="text-center main_row">
    <v-col cols="12" sm="10" md="10">
      <v-row justify="start" class="text-left">
        <v-col cols="12" md="8" class="pa-4">
          <v-card class="pa-6 card_left" color="transparent" flat>
            <div class="font2 pa-2 font_wrap title_card">
              More than <br class="d-none d-sm-flex" />
              200 IT-teams <br />
              <span style="color: #1e1e1e">in our platform!</span>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row justify="center" class="text-center">
        <v-col>
          <v-card href="#" color="#EFDAE9" class="cards pa-4">
            <div class="title_cards font1">Software Engineer</div>
          </v-card>
        </v-col>
        <v-col>
          <v-card href="#" color="#EFDAE9" class="cards pa-4">
            <div class="title_cards font1">Hardware Engineer</div>
          </v-card>
        </v-col>
        <v-col>
          <v-card href="#" color="#EFDAE9" class="cards pa-4">
            <div class="title_cards font1">Front-end</div>
          </v-card>
        </v-col>
        <v-col>
          <v-card href="#" color="#EFDAE9" class="cards pa-4">
            <div class="title_cards font1">UI / UX designer</div>
          </v-card>
        </v-col>
      </v-row>
      <v-row justify="center" class="text-center pl-lg-10 pr-lg-14">
        <v-col>
          <v-card href="#" color="#EFDAE9" class="cards pa-4">
            <div class="title_cards font1">Software Engineering Manager</div>
          </v-card>
        </v-col>
        <v-col>
          <v-card href="#" color="#EFDAE9" class="cards pa-4">
            <div class="title_cards font1">Finance / Accounting</div>
          </v-card>
        </v-col>
        <v-col>
          <v-card href="#" color="#EFDAE9" class="cards pa-4">
            <div class="title_cards font1">Human Resources</div>
          </v-card>
        </v-col>
      </v-row>
      <v-row justify="center" class="text-center">
        <v-col>
          <v-card href="#" color="#EFDAE9" class="cards pa-4">
            <div class="title_cards font1">Site Reliability / DevOps</div>
          </v-card>
        </v-col>
        <v-col>
          <v-card href="#" color="#EFDAE9" class="cards pa-4">
            <div class="title_cards font1">Product Manager</div>
          </v-card>
        </v-col>
        <v-col>
          <v-card href="#" color="#EFDAE9" class="cards pa-4">
            <div class="title_cards font1">Data Scientist</div>
          </v-card>
        </v-col>
        <v-col>
          <v-card href="#" color="#EFDAE9" class="cards pa-4">
            <div class="title_cards font1">IT Lawyer</div>
          </v-card>
        </v-col>
      </v-row>
      <v-row justify="center" class="text-center pl-lg-10 row_m_minus">
        <v-col>
          <v-card href="#" color="#EFDAE9" class="cards pa-4">
            <div class="title_cards font1">Recruiter</div>
          </v-card>
        </v-col>
        <v-col>
          <v-card href="#" color="#EFDAE9" class="cards pa-4">
            <div class="title_cards font1">Sales</div>
          </v-card>
        </v-col>
        <v-col>
          <v-card href="#" color="#EFDAE9" class="cards pa-4">
            <div class="title_cards font1">Marketing</div>
          </v-card>
        </v-col>
        <v-col>
          <v-card href="#" color="#EFDAE9" class="cards pa-4">
            <div class="title_cards font1">Data protection</div>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {};
</script>

<style scoped>
.row_m_minus {
  margin-right: -3rem;
}
.cards {
  border-radius: 10px;
  box-shadow: unset !important;
}
.title_cards {
  color: rgb(235, 116, 193);
  font-size: 1.05rem;
}
.main_row {
  position: relative;
}
.font2 {
  font-family: "Poppins", sans-serif !important;
  font-weight: 700 !important;
  font-size: 2rem;
  line-height: 3rem;
  color: #565656;
}
.title_card {
  color: #f2518b;
}
.card_left {
  overflow: visible;
  z-index: 1;
}
.text_card {
  font-size: 1.1rem;
  flex-direction: column;
  line-height: 1.6rem;
  color: #565656;
}
.img_right {
  position: absolute;
  right: 0%;
  top: 60%;
}
@media screen and (max-width: 820px) {
  .row_m_minus {
    margin-right: -12px !important;
  }
  .image_pages {
    top: 70%;
  }
}
@media screen and (max-width: 400px) {
  .font2 {
    font-size: 1.1rem;
    line-height: 2rem;
  }
  .text_card {
    font-size: 1rem;
  }
  .main_row {
    margin-bottom: 260px !important;
  }
}
</style>