<template>
  <v-row justify="center" class="text-center">
    <v-col cols="10" sm="12" md="10">
      <v-row justify="center" class="text-center">
        <div class="font2 title_text">Our team</div>
      </v-row>
      <v-row justify="center" class="text-center">
        <v-col cols="10" md="6">
          <hr class="hr_blue" />
        </v-col>
      </v-row>
      <v-row justify="center" class="text-center mt-15">
        <v-col cols="12" sm="4">
          <v-card width="100%" class="pa-8" color="transparent" flat>
            <v-img
              width="45%"
              :src="require('../../assets/About/1.png')"
              class="rounded-circle mx-auto"
            ></v-img>
            <div class="card_title mt-8">Harvey Specter</div>
            <div class="card_subtitle">Chief Executive Officer</div>
            <div class="card_text mt-6">
              Proin ac mi vel neque tristique consequat. Duis mollis arcu risus,
              quis pharetra felis faucibus vitae.
            </div>
            <v-card-actions class="justify-center">
              <v-btn href="#" text color="#4024EA" class="card_buttons mt-6">
                <v-icon class="mr-1">mdi-facebook</v-icon>Facebook
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4">
          <v-card width="100%" class="pa-8" color="transparent" flat>
            <v-img
              width="45%"
              :src="require('../../assets/About/2.png')"
              class="rounded-circle mx-auto"
            ></v-img>
            <div class="card_title mt-8">Mike Ross</div>
            <div class="card_subtitle">Business Development Director</div>
            <div class="card_text mt-6">
              Proin ac mi vel neque tristique consequat. Duis mollis arcu risus,
              quis pharetra felis faucibus vitae.
            </div>
            <v-card-actions class="justify-center">
              <v-btn href="#" text color="#4024EA" class="card_buttons mt-6">
                <v-icon class="mr-1">mdi-facebook</v-icon>Facebook
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4">
          <v-card width="100%" class="pa-8" color="transparent" flat>
            <v-img
              width="45%"
              :src="require('../../assets/About/3.png')"
              class="rounded-circle mx-auto"
            ></v-img>
            <div class="card_title mt-8">Jessica Pearson</div>
            <div class="card_subtitle">Senior HR expert</div>
            <div class="card_text mt-6">
              Proin ac mi vel neque tristique consequat. Duis mollis arcu risus.
              Aliquam erat diam, condimentum vel pharetra vel, laoreet id nisl.
            </div>
            <v-card-actions class="justify-center">
              <v-btn href="#x" text color="#4024EA" class="card_buttons mt-6">
                <v-icon class="mr-1">mdi-facebook</v-icon>Facebook
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Our-Team",
};
</script>
<style scoped>
.card_buttons {
  font-family: "Poppins", sans-serif !important;
  font-weight: 500 !important;
  font-size: 1rem;
  line-height: 1.6rem;
  text-transform: none;
}
.card_text {
  font-family: "Poppins", sans-serif !important;
  font-weight: 400 !important;
  font-size: 1rem;
  line-height: 1.6rem;
  color: #565656;
}
.card_subtitle {
  font-family: "Poppins", sans-serif !important;
  font-weight: 400 !important;
  font-size: 1rem;
  line-height: 2rem;
  color: #565656;
}
.card_title {
  font-family: "Poppins", sans-serif !important;
  font-weight: 600 !important;
  font-size: 1.45rem;
  line-height: 2rem;
}
.hr_blue {
  border: unset;
  background: rgba(46, 92, 255, 0.24);
  height: 3px;
}
.title_text {
  color: #4024ea;
}
.font2 {
  font-family: "Poppins", sans-serif !important;
  font-weight: 700 !important;
  font-size: 2.3rem;
  line-height: 3rem;
}
@media screen and (max-width: 400px) {
}
</style>