<template>
  <v-row justify="center" class="text-center">
    <v-col cols="11">
      <v-row justify="center" class="text-center">
        <v-col cols="12" sm="10" md="5">
          <v-card class="card_left pa-8" flat>
            <v-row justify="start" class="text-center">
              <v-col cols="12" sm="7" class="text-start">
                <div class="title_card_left">Connect us</div>
                <div class="content_card_left">+ 373 79 788 688</div>
                <div class="content_card_left_blue">contact@jobit.com</div>
              </v-col>
              <v-col cols="12" sm="5" class="text-start">
                <div class="title_card_left">Work schedule</div>
                <div class="content_card_left">Mon-fri 9:00-20:00</div>
              </v-col>
            </v-row>
            <v-row justify="start" class="text-center mt-sm-15">
              <v-col cols="12" sm="7" class="text-start">
                <div class="title_card_left">Adress</div>
                <div class="content_card_left">
                  Moldova, Chisinau mun.<br />
                  Str 31 August 1989, 43/3<br />
                  MD-2001
                </div>
              </v-col>
              <v-col cols="12" sm="5" class="text-start">
                <div class="title_card_left">Social Networks</div>
                <div class="content_card_left">
                  <v-btn href="#" icon>
                    <svg
                      width="12"
                      viewBox="0 0 9 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0 5.72727V9H2.45455V14.7273H5.72727V9H8.18182L9 5.72727H5.72727V4.09091C5.72727 3.63904 6.09359 3.27273 6.54545 3.27273H9V0H6.54545C4.28611 0 2.45455 1.83156 2.45455 4.09091V5.72727H0Z"
                        fill="#4024EA"
                      />
                    </svg>
                  </v-btn>
                  <v-btn href="#" icon>
                    <svg
                      width="20"
                      viewBox="0 0 14 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.5 4.75L6 7.25L9.75 11L12.25 1L1 5.375L3.5 6.625L4.75 10.375L6.625 7.875"
                        stroke="#4024EA"
                        stroke-width="2"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" sm="10" md="7">
          <v-card class="card_right" flat></v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Four-Page",
};
</script>
<style scoped>
.card_right {
  height: 70vh;
}
.content_card_left_blue {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 600 !important;
  font-size: 1rem;
  line-height: 1.6rem;
  color: #4024ea;
}
.content_card_left {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 600 !important;
  font-size: 1rem;
  line-height: 2rem;
  color: #565656;
}
.title_card_left {
  font-family: "Poppins", sans-serif !important;
  font-weight: 400 !important;
  font-size: 0.9rem;
  line-height: 3rem;
  color: #565656;
}
.card_right {
  border-radius: 23px;
  background: rgba(255, 255, 255, 0.41);
}
.card_left {
  height: 100%;
  border-radius: 23px;
  background: #ffffff;
}
.font2 {
  font-family: "Poppins", sans-serif !important;
  font-weight: 700 !important;
  font-size: 2rem;
  line-height: 3rem;
  color: #2b2b2b;
}
@media screen and (max-width: 400px) {
}
</style>