<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>
<style scoped>
.app {
  background: #fafafa;
}
</style>
<style>
.text_logo {
  letter-spacing: 0.001em !important;
}
.font2 {
  letter-spacing: 0.001em !important;
}
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
.switch_developers .v-messages {
  display: none;
}
.switch_developers .v-input__slot {
  margin-bottom: 0px;
}
.switch_developers .v-input--switch__thumb {
  color: #4024EA !important;
}
.switch_developers .v-input--switch__track {
  color: #fafafa !important;
  border: 2px solid #4024EA;
  opacity: 1 !important;
}
.switch_hr .v-messages {
  display: none;
}
.switch_hr .v-input__slot {
  margin-bottom: 0px;
}
.switch_hr .v-input--switch__thumb {
  color: #f2518b !important;
}
.switch_hr .v-input--switch__track {
  color: #fafafa !important;
  border: 2px solid #f2518b;
  opacity: 1 !important;
}
a {
  color: unset !important;
  text-decoration: none;
}
.font_wrap {
  word-wrap: break-word !important;
  word-break: normal !important;
}
.font1 {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 600 !important;
  letter-spacing: 0.001em !important;
}
html {
  scroll-behavior: smooth;
}
</style>