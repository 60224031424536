<template>
  <v-row justify="center" class="text-left">
    <v-col cols="12" sm="11" lg="9">
      <v-card class="card_main" color="#ebebeb" flat>
        <div class="font2 pt-8 pl-6 pl-sm-10 faq">FAQ</div>

        <v-expansion-panels
          accordion
          v-model="panel"
          multiple
          class="transparent"
          flat
        >
          <v-expansion-panel class="panel" v-for="(item, i) in items" :key="i">
            <v-expansion-panel-header class="font3 exp_panel_header pl-4 pl-sm-10">
              <div class="ml-sm-6" v-html="item.title"></div>

              <template v-slot:actions>
                <v-icon v-if="!panel.includes(i)" color="#4024EA">
                  mdi-chevron-right
                </v-icon>
                <v-icon v-else color="#4024EA">mdi-chevron-up</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content
              class="panel_content pa-6 pa-sm-10 font1"
              v-html="item.text"
            >
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          title: "How exactly the system works  ",
          text: "Ut eu sem convallis, tempus dolor eget, semper sem. Vivamus laoreet dolor vel sem facilisis fringilla. Nam at elementum nibh. Nunc placerat metus non magna imperdiet mattis. Curabitur sem nisi, porttitor sit amet porta…",
        },
        {
          title: "How to work with multiply candidates ",
          text: "Ut eu sem convallis, tempus dolor eget, semper sem. Vivamus laoreet dolor vel sem facilisis fringilla. Nam at elementum nibh. Nunc placerat metus non magna imperdiet mattis. Curabitur sem nisi, porttitor sit amet porta…",
        },
        {
          title: "How to find new specialists ",
          text: "Ut eu sem convallis, tempus dolor eget, semper sem. Vivamus laoreet dolor vel sem facilisis fringilla. Nam at elementum nibh. Nunc placerat metus non magna imperdiet mattis. Curabitur sem nisi, porttitor sit amet porta…",
        },
        {
          title:
            "What information is visable for <span class='text_rose'>Specialist users</span>? ",
          text: "Ut eu sem convallis, tempus dolor eget, semper sem. Vivamus laoreet dolor vel sem facilisis fringilla. Nam at elementum nibh. Nunc placerat metus non magna imperdiet mattis. Curabitur sem nisi, porttitor sit amet porta…",
        },
        {
          title: "Another question ",
          text: "Ut eu sem convallis, tempus dolor eget, semper sem. Vivamus laoreet dolor vel sem facilisis fringilla. Nam at elementum nibh. Nunc placerat metus non magna imperdiet mattis. Curabitur sem nisi, porttitor sit amet porta…",
        },
      ],
      panel: [4],
    };
  },
};
</script>
<style>
.text_rose {
  color: #4024EA !important;
}
</style>
<style scoped>
.panel {
  background: #f3f3f3 !important;
}
.faq {
  background: #f3f3f3 !important;
}
.panel_content {
  background: #ebebeb;
  color: #2b2b2b;
}
.exp_panel_header {
  flex-direction: row-reverse;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.card_main {
  overflow: hidden;
  border-radius: 10px;
}

.list_text {
  color: #565656;
  font-size: 1.1rem;
}
.font3 {
  font-family: "Poppins", sans-serif !important;
  font-weight: 700 !important;
  font-size: 1.5rem;
  line-height: 2.5rem;
  color: #1e1e1e;
}
.font2 {
  font-family: "Poppins", sans-serif !important;
  font-weight: 700 !important;
  font-size: 2.2rem;
  line-height: 3.5rem;
  color: #2b2b2b;
}
@media screen and (max-width: 400px) {
  .font2 {
    font-size: 1.1rem;
    line-height: 2.6rem;
  }
  .font3 {
    font-size: .9rem;
    line-height: 1.6rem;
  }
  .panel_content {
    font-size: .85rem;
  }
}
</style>