<template>
  <v-row justify="center" class="text-center">
    <v-col cols="12" sm="10" lg="9" offset-lg="1">
      <v-timeline dense class="timeline_number">
        <v-timeline-item
          v-for="(item, i) in items"
          :key="i"
          class="py-10 py-sm-15"
          color="#4024ea"
          fill-dot
          large
        >
          <template v-slot:icon>
            <div class="text_number pa-10 pa-sm-12">
              <span>{{ i + 1 }}.</span>
            </div>
          </template>
          <v-card class="elevation-0 ml-4 ml-sm-10" flat color="transparent">
            <div class="text-left font2 font_wrap" v-text="item.title"></div>
            <div
              class="text-left font1 list_text font_wrap"
              v-text="item.text"
            ></div>
          </v-card>
        </v-timeline-item>
      </v-timeline>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "StepsPage",
  data() {
    return {
      items: [
        {
          title: "Register and fill main information.",
          text: "Just your email and  name. ",
        },
        {
          title: "Complete your profile with essential information.",
          text: "Fill in all the required information to start working in our system as soon as possible! ",
        },
        {
          title: "Wait for accept by our staff.  ",
          text: "Your application and information will be checked by our moderators. Some details and work experience can be verified. ",
        },
        {
          title: "Enter your dashboard and find best job offers.",
          text: "Your profile is included in our data base. Start searching best offers! ",
        },
        { title: "Find and accept offers to work", text: "It is free!  " },
      ],
    };
  },
};
</script>
<style>
.timeline_number::before {
  background: #4024ea !important;
  width: 14px !important;
  left: calc(48px - 6px) !important;
  height: 85% !important;
  top: 8% !important;
}
</style>
<style scoped>
.text_number {
  aspect-ratio: 1;
  background: #4024ea;
  border-radius: 100%;
  font-size: 2rem;
  font-family: "Poppins", sans-serif !important;
  font-weight: 700 !important;
  color: #fff;
  position: relative;
}
.text_number span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-45%, -50%);
}
.list_text {
  color: #565656;
  font-size: 1.1rem;
}
.font2 {
  font-family: "Poppins", sans-serif !important;
  font-weight: 700 !important;
  font-size: 2rem;
  line-height: 3rem;
  color: #2b2b2b;
}
@media screen and (max-width: 400px) {
  .font2 {
    font-size: 1.1rem;
    line-height: 1.8rem;
  }
  .list_text {
    color: #565656;
    font-size: 0.85rem;
  }
}
</style>