<template>
  <v-row justify="center" class="text-center main_row">
    <v-col cols="12" sm="10" md="8">
      <v-row justify="center" class="text-left">
        <v-col cols="12" md="8" class="pa-4">
          <v-card class="pa-6 card_left" color="transparent" flat>
            <div class="font2 pa-2 font_wrap title_card">
              200 developers<br class="d-none d-sm-flex" />
              already receive projects through<br />
              Hi-base
            </div>
            <div class="font1 text_card pa-2 font_wrap d-none d-sm-flex">
              <p>
                More and more specialists getting involved in workflow of
                hi-base. We are working hard to provide only safe and profitable
                options for you
              </p>
              <p>
                We collect feedback from all sides of the project: toxic and
                incompetent people do not stay with us.
              </p>
              <p>
                Register, find the best opportunities and get a high rating in
                our system
              </p>
            </div>
          </v-card>
          <v-img
            class="d-sm-none img_right"
            position="right"
            max-width="120%"
            max-height="140%"
            contain
            :src="require('../../assets/HomeForDevelopers/4.png')"
          ></v-img>
        </v-col>
        <v-col cols="12" sm="4" class="d-none d-sm-flex">
          <v-img
            class="image_pages"
            max-width="45%"
            max-height="120%"
            position="right"
            contain
            :src="require('../../assets/HomeForDevelopers/4.png')"
          ></v-img>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {};
</script>

<style scoped>
.image_pages {
  position: absolute;
  right: 0%;
  z-index: 0;
}
.main_row {
  position: relative;
}
.font2 {
  font-family: "Poppins", sans-serif !important;
  font-weight: 700 !important;
  font-size: 2rem;
  line-height: 3rem;
  color: #565656;
}
.title_card {
  color: #4024ea;
}
.card_left {
  overflow: visible;
  z-index: 1;
}
.text_card {
  font-size: 1.1rem;
  flex-direction: column;
  line-height: 1.6rem;
  color: #565656;
}
.img_right {
  position: absolute;
  right: 0%;
  top: 60%;
}
@media screen and (max-width: 820px) {
  .image_pages {
    top: 70%;
  }
}
@media screen and (max-width: 400px) {
  .font2 {
    font-size: 1.1rem;
    line-height: 2rem;
  }
  .text_card {
    font-size: 1rem;
  }
  .main_row {
    margin-bottom: 260px !important;
  }
}
</style>