<template>
  <v-main class="main">
    <v-navigation-drawer
      v-model="drawer"
      temporary
      app
      right
      class="d-flex d-md-none"
    >
      <v-list>
        <v-list-item class="px-8 font1" href="/" link>
          <v-list-item-content>
            <v-list-item-title>Hi base</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item
          class="px-8 font1"
          v-for="item in menu"
          :key="item.title"
          :href="item.link"
          link
        >
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-btn
            href="/login"
            class="
              buttons
              mx-2 mx-lg-4
              white--text
              px-7 px-lg-10
              text-capitalize
            "
            elevation="0"
            color="#4024EA"
          >
            Log in
          </v-btn>
          <v-btn
            href="/register"
            class="
              buttons
              mx-2
              border_button
              px-4 px-lg-7
              text-capitalize
              font1
            "
            elevation="0"
            outlined
          >
            Register
          </v-btn>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app color="#fff" class="px-2 px-sm-6" elevation="0" height="85px" width="100%">
      <router-link to="/"><div class="d-flex align-center text_logo"><h3>Hi base</h3></div></router-link>

      <v-spacer></v-spacer>

      <v-toolbar-items class="d-none d-md-flex">
        <v-btn
          class="text-capitalize font1 text_menu px-8 px-lg-15"
          text
          v-for="(item, i) in menu"
          :key="i"
          :href="item.link"
          v-text="item.title"
        ></v-btn>
      </v-toolbar-items>
      <v-switch
        class="d-flex align-center switch_hr ml-5 mr-3 ml-lg-6 mr-lg-6"
        color="#F2518B"
        v-model="switchHR"
        inset
      ></v-switch>
      <v-btn
        icon
        outlined
        small
        class="pa-4 mx-1 ml-lg-8 border_button buttons"
      >
        <svg
          width="16"
          height="14"
          viewBox="0 0 16 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.9544 1.2745L13.5399 13.1549C13.3577 13.9934 12.8827 14.202 12.2076 13.8071L8.5287 10.9786L6.7536 12.7599C6.55721 12.9648 6.39286 13.1363 6.01426 13.1363L6.27853 9.22704L13.097 2.79866C13.3935 2.52292 13.0327 2.3701 12.6363 2.64588L4.20695 8.18358L0.578034 6.99855C-0.211324 6.7414 -0.225585 6.17494 0.742313 5.77995L14.9365 0.0745518C15.5936 -0.182566 16.1687 0.227298 15.9544 1.2745Z"
            fill="#2B2B2B"
          />
        </svg>
      </v-btn>
      <v-btn
        href="/login"
        class="buttons mx-1 white--text px-7 px-lg-10 text-capitalize d-none d-sm-flex"
        elevation="0"
        color="#4024EA"
      >
        Log in
      </v-btn>
      <v-btn
        href="/register"
        class="buttons mx-1 border_button px-4 px-lg-7 text-capitalize font1 d-none d-sm-flex"
        elevation="0"
        outlined
      >
        Register
      </v-btn>
      <v-btn
        class="pa-4 mx-2 mx-lg-4 border_button buttons d-flex d-md-none"
        elevation="0"
        small
        icon
        @click="drawer = !drawer"
      >
       <v-icon>mdi-menu</v-icon>
      </v-btn>
    </v-app-bar>

    <v-container class="mt-6 text-center" fluid>
        <Hi-Base class="my-1 py-1 mb-sm-15 pb-sm-15" />
        <Second-Page class="my-1 py-1 my-sm-15 py-sm-15" />
        <Our-Team class="my-8 py-8 my-sm-15 py-sm-15" />
        <Four-Page class="my-1 py-1 my-sm-15 py-sm-15" />
    </v-container>
    <Footer-Page class="mt-sm-15 pt-15" />
  </v-main>
</template>

<script>
import HiBase from "../components/AboutDevelopers/HiBase";
import SecondPage from "../components/AboutDevelopers/SecondPage";
import OurTeam from "../components/AboutDevelopers/OurTeam";
import FourPage from "../components/AboutDevelopers/FourPage";
import FooterPage from "../components/FooterPage";

export default {
  name: "About-Developers",
  components: {
    HiBase,
    SecondPage,
    OurTeam,
    FourPage,
    FooterPage
  },
  data() {
    return {
      switchHR: false,
      drawer: false,
      menu: [
        {
          title: "How to start",
          link: "/howtostartdevelopers",
        },
        {
          title: "About",
          link: "/aboutdevelopers",
        },
        {
          title: "Contact us",
          link: "/#",
        },
        {
          title: "For HR's",
          link: "/#",
        },
      ],
    };
  },
  watch: {
    switchHR(switchHR) {
      if (switchHR == true) {
        this.$router.push({name: "HomeForHRs"});
      }
    }
  },
};
</script>

<style scoped>
.main {
  background: linear-gradient(120deg, #3867eadd, rgba(255, 0, 0, 0) 100%),
    linear-gradient(266deg, #f2518cb7, rgba(0, 255, 0, 0) 50%),
    linear-gradient(336deg, #ffffffa8, rgba(0, 0, 255, 0) 50%);
}
.text_menu {
  font-size: 16px;
}
.buttons {
  border-radius: 10px;
  letter-spacing: 0.0212857143em;
  font-weight: 600;
}
.border_button {
  border: 1px solid #b5b5b5;
}
</style>