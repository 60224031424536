<template>
  <v-row justify="center" class="text-left">
    <v-col cols="12" sm="11" lg="9">
      <v-row justify="center" class="text-left">
        <v-col cols="12" sm="6" class="pr-lg-15">
          <div class="font2 font_wrap mr-12 mr-sm-0">
            Have some difficulties? <br />
            Download a brochure to see how everything works
          </div>
          <v-row class="ma-0 pt-6">
            <v-col cols="6" sm="6" class="pl-0">
              <v-btn text width="100%" class="buttons font1">
                <svg
                  class="mr-2"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="12"
                    cy="12"
                    r="9"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8 12L12 16"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12 8V16"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16 12L12 16"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                Download now
              </v-btn>
            </v-col>
            <v-col cols="6" sm="6" class="pr-0">
              <v-btn text width="100%" class="buttons font1">
                <svg
                  class="mr-2"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M15 10L19.553 7.724C19.8629 7.56914 20.2309 7.58571 20.5256 7.7678C20.8203 7.94989 20.9998 8.27156 21 8.618V15.382C20.9998 15.7284 20.8203 16.0501 20.5256 16.2322C20.2309 16.4143 19.8629 16.4309 19.553 16.276L15 14V10Z"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <rect
                    x="3"
                    y="6"
                    width="12"
                    height="12"
                    rx="2"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                Check tutorial
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="11" sm="6">
          <v-img max-width="100%" contain :src="require('../../assets/HowToStartDevelopers/1.png')"></v-img>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "BrochurePage",
};
</script>
<style scoped>
.buttons {
  border-radius: 10px;
  letter-spacing: 0.0212857143em;
  font-weight: 600;
  font-size: 1.06rem;
  border: 1px solid #b5b5b5;
  text-transform: none;
}
.font2 {
  font-family: "Poppins", sans-serif !important;
  font-weight: 700 !important;
  font-size: 2rem;
  line-height: 3rem;
  color: #4024ea;
}
@media screen and (max-width: 400px) {
  .buttons {
    font-size: 0.9rem;
  }
  .font2 {
    font-size: 1.3rem;
    line-height: 2rem;
  }
}
</style>