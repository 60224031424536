<template>
  <v-row justify="center" class="text-center">
    <v-col cols="10" md="10">
      <v-row justify="start" class="text-center mb-sm-12">
        <div class="font2 d-none d-sm-flex">
          How it works
          <svg
            class="svg_linii"
            width="54"
            height="40"
            viewBox="0 0 54 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="4.26215"
              y1="17.7835"
              x2="1.7835"
              y2="3.73785"
              stroke="#4024EA"
              stroke-width="3"
              stroke-linecap="round"
            />
            <line
              x1="24.1222"
              y1="23.0688"
              x2="32.0688"
              y2="1.87781"
              stroke="#4024EA"
              stroke-width="3"
              stroke-linecap="round"
            />
            <line
              x1="35.889"
              y1="38.0739"
              x2="52.0739"
              y2="32.111"
              stroke="#4024EA"
              stroke-width="3"
              stroke-linecap="round"
            />
          </svg>
        </div>
        <v-card-title class="font2 d-sm-none ma-auto pa-0">How? Here are the steps!</v-card-title>
      </v-row>
      <v-row justify="center" class="text-center">
        <v-col cols="12" sm="6" md="3" class="pa-4">
          <v-card class="cards pa-6 text-left" elevation="0">
            <v-card-actions class="mt-12">
              <svg
                width="45"
                height="45"
                viewBox="0 0 45 45"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.875 9.375H13.125C11.0539 9.375 9.375 11.0539 9.375 13.125V35.625C9.375 37.6961 11.0539 39.375 13.125 39.375H31.875C33.9461 39.375 35.625 37.6961 35.625 35.625V13.125C35.625 11.0539 33.9461 9.375 31.875 9.375H28.125"
                  stroke="#4024EA"
                  stroke-width="5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <rect
                  x="16.875"
                  y="5.625"
                  width="11.25"
                  height="7.5"
                  rx="2"
                  stroke="#4024EA"
                  stroke-width="5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.875 26.25L20.625 30L28.125 22.5"
                  stroke="#4024EA"
                  stroke-width="5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </v-card-actions>
            <div class="font1 text_card pa-2">
              <u>Choose your plan</u><br />
              Enter <span class="text_blue">Hi-base</span> database of recent
              workplaces
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="3" class="pa-4">
          <v-card class="cards pa-6 text-left" elevation="0">
            <v-card-actions class="mt-12">
              <svg
                width="45"
                height="45"
                viewBox="0 0 45 45"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.625 22.5H11.25"
                  stroke="#4024EA"
                  stroke-width="5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M22.5 5.625V11.25"
                  stroke="#4024EA"
                  stroke-width="5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.625 14.625L10.5 10.5"
                  stroke="#4024EA"
                  stroke-width="5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M30.375 14.625L34.5 10.5"
                  stroke="#4024EA"
                  stroke-width="5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.625 30.375L10.5 34.5"
                  stroke="#4024EA"
                  stroke-width="5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M22.5 22.5L39.375 28.125L31.875 31.875L28.125 39.375L22.5 22.5"
                  stroke="#4024EA"
                  stroke-width="5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </v-card-actions>
            <div class="font1 text_card pa-2">
              Find approved employer or projects for your tasks
              <span class="text_blue">quickly.</span>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="3" class="pa-4">
          <v-card class="cards pa-6 text-left" elevation="0">
            <v-card-actions class="mt-12">
              <svg
                width="45"
                height="45"
                viewBox="0 0 45 45"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="5.625"
                  y="30"
                  width="5.625"
                  height="9.375"
                  rx="1"
                  stroke="#4024EA"
                  stroke-width="4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M11.25 37.5C11.25 38.5355 12.0895 39.375 13.125 39.375H20.1675C20.9958 39.375 21.726 38.8316 21.9638 38.0381L24.2138 32.4131C24.3825 31.845 24.4631 31.2319 24.1087 30.7556C23.7562 30.2794 23.0925 30 22.5 30H18.75V25.3744C18.7495 24.4658 18.1547 23.6646 17.2851 23.4014C16.4155 23.1381 15.4762 23.4749 14.9719 24.2306L11.25 30V37.5H11.25Z"
                  stroke="#4024EA"
                  stroke-width="4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M26.25 5.625V13.125C26.25 14.1605 27.0895 15 28.125 15H35.625"
                  stroke="#4024EA"
                  stroke-width="4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M9.375 22.6875V9.375C9.375 7.30393 11.0539 5.625 13.125 5.625H26.25L35.625 15V35.625C35.625 37.6961 33.9461 39.375 31.875 39.375H27.5625"
                  stroke="#4024EA"
                  stroke-width="4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </v-card-actions>
            <div class="font1 text_card pa-2">
              Accept recruiter’s request and
              <span class="text_blue">start working with them!</span>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="3" class="pa-4">
          <v-card class="cards pa-6 text-left" elevation="0">
            <v-card-actions class="mt-12">
              <svg
                width="39"
                height="40"
                viewBox="0 0 39 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M24.5 7.5C24.5 6.39543 23.6046 5.5 22.5 5.5C21.3954 5.5 20.5 6.39543 20.5 7.5H24.5ZM20.5 13.125C20.5 14.2296 21.3954 15.125 22.5 15.125C23.6046 15.125 24.5 14.2296 24.5 13.125H20.5ZM17 7.5C17 6.39543 16.1046 5.5 15 5.5C13.8954 5.5 13 6.39543 13 7.5H17ZM13 18.75C13 19.8546 13.8954 20.75 15 20.75C16.1046 20.75 17 19.8546 17 18.75H13ZM32 7.5C32 6.39543 31.1046 5.5 30 5.5C28.8954 5.5 28 6.39543 28 7.5H32ZM28 18.75C28 19.8546 28.8954 20.75 30 20.75C31.1046 20.75 32 19.8546 32 18.75H28ZM20.5 7.5V13.125H24.5V7.5H20.5ZM13 7.5V18.75H17V7.5H13ZM28 7.5V18.75H32V7.5H28Z"
                  fill="#4024EA"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M22.5 34.6875L16.875 37.5L17.8125 30.9375L14.0625 27.1875L19.6875 26.25L22.5 20.625L25.3125 26.25L30.9375 27.1875L27.1875 30.9375L28.125 37.5L22.5 34.6875Z"
                  stroke="#4024EA"
                  stroke-width="4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </v-card-actions>
            <div class="font1 text_card pa-2">
              Get a higher rank, approvement by us
              <span class="text_blue">and keep being productive!</span>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {};
</script>

<style scoped>
.text_blue {
  color: #4024ea;
}
.text_card {
  font-size: 1.1rem;
  line-height: 1.6rem;
}
.cards {
  background: #fff;
  border: 1px solid #d8d8d8;
  border-radius: 19px;
  height: 100%;
}
.font2 {
  font-family: "Poppins", sans-serif !important;
  font-weight: 700 !important;
  font-size: 2rem;
  line-height: 3rem;
  color: #2b2b2b;
  position: relative;
}
.svg_linii {
  position: absolute;
  top: -20px;
  right: -38px;
}
@media screen and (max-width: 400px) {
  .font2 {
    font-size: 1.1rem;
    line-height: 2rem;
  }
}
</style>