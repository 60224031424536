import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeForDevelopers from '../views/HomeForDevelopers.vue'
import HowToStartDevelopers from '../views/HowToStartDevelopers.vue'
import AboutDevelopers from '../views/AboutDevelopers.vue'
import HomeForHRs from '../views/HomeForHRs.vue'
import HowToStartHRs from '../views/HowToStartHRs.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomeForDevelopers',
    component: HomeForDevelopers
  },
  {
    path: '/howtostartdevelopers',
    name: 'HowToStartDevelopers',
    component: HowToStartDevelopers
  },
  {
    path: '/aboutdevelopers',
    name: 'AboutDevelopers',
    component: AboutDevelopers
  },
  {
    path: '/hrs',
    name: 'HomeForHRs',
    component: HomeForHRs
  },
  {
    path: '/howtostarthrs',
    name: 'HowToStartHRs',
    component: HowToStartHRs
  },
  {
    path: '/login',
    name: 'LogIn',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
]

const router = new VueRouter({
  routes,
  mode: "history"
})

export default router
