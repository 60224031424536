<template>
  <v-row justify="center" class="text-center">
    <v-col cols="12" md="8">
      <v-row justify="center" class="text-center mt-8 mb-sm-12">
        <div class="font2">
          <svg
            class="d-sm-none"
            width="20"
            height="20"
            viewBox="0 0 45 45"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.8123 20.625H17.831"
              stroke="black"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M27.1873 20.625H27.206"
              stroke="black"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M17.8125 28.125C19.0468 29.3848 20.7363 30.0947 22.5 30.0947C24.2637 30.0947 25.9532 29.3848 27.1875 28.125"
              stroke="black"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M13.125 9.375H15V5.625H30V9.375H31.875C34.9816 9.375 37.5 11.8934 37.5 15V31.875C37.5 34.9816 34.9816 37.5 31.875 37.5V39.375H13.125V37.5C10.0184 37.5 7.5 34.9816 7.5 31.875V15C7.5 11.8934 10.0184 9.375 13.125 9.375"
              stroke="black"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          You are suitable for us if
        </div>
      </v-row>
      <v-row justify="center" class="text-left pt-sm-12">
        <v-col cols="12">
          <v-card class="pa-6" color="transparent" flat>
            <svg
              class="d-none d-sm-flex"
              width="45"
              height="45"
              viewBox="0 0 45 45"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.8123 20.625H17.831"
                stroke="black"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M27.1873 20.625H27.206"
                stroke="black"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M17.8125 28.125C19.0468 29.3848 20.7363 30.0947 22.5 30.0947C24.2637 30.0947 25.9532 29.3848 27.1875 28.125"
                stroke="black"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M13.125 9.375H15V5.625H30V9.375H31.875C34.9816 9.375 37.5 11.8934 37.5 15V31.875C37.5 34.9816 34.9816 37.5 31.875 37.5V39.375H13.125V37.5C10.0184 37.5 7.5 34.9816 7.5 31.875V15C7.5 11.8934 10.0184 9.375 13.125 9.375"
                stroke="black"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <div class="font3 mt-2">
              You are able to work in a team and<br />
              approach tasks responsibly
            </div>
            <v-list color="transparent" class="mt-2">
              <v-list-item v-for="(item, i) in items" :key="i" class="pl-0">
                <v-list-item-icon class="my-3 mr-2 mr-sm-8">
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="11"
                      cy="11"
                      r="11"
                      fill="#4024EA"
                      fill-opacity="0.19"
                    />
                    <path
                      d="M7.85718 11.0002L10.2143 13.3574L14.9286 8.64307"
                      stroke="#4024EA"
                      stroke-opacity="0.55"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </v-list-item-icon>
                <v-list-item-content
                  class="text-left font1 list_text font_wrap"
                  v-text="item.text"
                >
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "YouSuitablePage",
  data: () => ({
    items: [
      { text: "Stay connected during business hours. " },
      { text: "Advance notice of delays." },
      {
        text: "Get involved in the customer's business and help formulate solutions.",
      },
      {
        text: "You know how to manage expectations: do not promise too much and meet deadlines.",
      },
    ],
  }),
};
</script>

<style scoped>
.list_text {
  color: #565656;
}
.font2 {
  font-family: "Poppins", sans-serif !important;
  font-weight: 700 !important;
  font-size: 2.4rem;
  line-height: 3.8rem;
  color: #2b2b2b;
}
.font3 {
  font-family: "Poppins", sans-serif !important;
  font-weight: 700 !important;
  font-size: 1.9rem;
  line-height: 3rem;
  color: #2b2b2b;
}
@media screen and (max-width: 400px) {
  .font2 {
    font-size: 1.3rem;
    line-height: 3.8rem;
  }
  .font3 {
    font-size: 1rem;
    line-height: 2rem;
  }
  .list_text {
    font-size: 0.8rem;
  }
}
</style>