<template>
  <footer class="footer_page">
    <v-row justify="center" class="text-center pt-6 mx-0">
      <v-col cols="12" md="9">
        <v-row justify="center" class="text-left row_options mb-2 pb-12 mx-0">
          <v-col cols="6" md="3" class="pl-6">
            <div class="font1 px-4">
              <router-link to="#">Projects</router-link>
            </div>
            <v-list color="transparent" class="mt-12">
              <v-list-item v-for="(item, i) in group1" :key="i">
                <v-list-item-content>
                  <router-link to="#">
                    <v-list-item-title
                      class="font1 list_text"
                      v-text="item.text"
                    ></v-list-item-title>
                  </router-link>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="6" md="3" class="pl-6">
            <div class="font1 px-4">
              <router-link to="#">Insights</router-link>
            </div>
            <v-list color="transparent" class="mt-12">
              <v-list-item v-for="(item, i) in group2" :key="i">
                <v-list-item-content>
                  <router-link to="#">
                    <v-list-item-title
                      class="font1 list_text"
                      v-text="item.text"
                    ></v-list-item-title>
                  </router-link>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="6" md="3" class="pl-6">
            <div class="font1 px-4">
              <router-link to="#">Terms and Conditions</router-link>
            </div>
            <v-list color="transparent" class="mt-12">
              <v-list-item v-for="(item, i) in group3" :key="i">
                <v-list-item-content>
                  <router-link to="#">
                    <v-list-item-title
                      class="font1 list_text"
                      v-text="item.text"
                    ></v-list-item-title>
                  </router-link>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="6" md="3" class="pl-6">
            <div class="font1 px-4">
              <router-link to="#">Contact us</router-link>
            </div>
            <v-list color="transparent" class="mt-12">
              <v-list-item v-for="(item, i) in group4" :key="i">
                <v-list-item-content>
                  <router-link to="#">
                    <v-list-item-title
                      class="font1 list_text"
                      v-text="item.text"
                    ></v-list-item-title>
                  </router-link>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
        <v-row justify="end" class="mb-4">
          <v-col cols="6" md="4" lg="2" class="mr-2 mr-md-0 pa-0">
            <v-img
              contain
              max-width="100%"
              :src="require('../assets/Others/f1.png')"
            ></v-img>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center" class="text-center ma-0 row_black py-2">
      All copyrights reserved. 2022 C
    </v-row>
  </footer>
</template>

<script>
export default {
  name: "FooterPage",
  data: () => ({
    group1: [
      { text: "Link" },
      { text: "HR-Test" },
      { text: "Start-Ups" },
      { text: "Partners" },
      { text: "General Client’s" },
    ],
    group2: [
      { text: "Link" },
      { text: "HR-Test" },
      { text: "Start-Ups" },
      { text: "Partners" },
      { text: "General Client’s" },
    ],
    group3: [
      { text: "Documentation" },
      { text: "GDPR" },
      { text: "Legal" },
      { text: "Complience" },
      { text: "Cookies" },
    ],
    group4: [
      { text: "+ 373 79 788 688" },
      { text: "contact@jobit.com" },
      { text: "Legal" },
      { text: "Complience" },
      { text: "Cookies" },
    ],
  }),
};
</script>

<style scoped>
.row_black {
  background: #2b2b2b;
  color: #fff;
}
.row_options {
  border-bottom: 2px solid rgba(86, 86, 86, 0.453);
}
.list_text {
  color: #565656;
}
.footer_page {
  background: #e7e7eb;
}
</style>