<template>
  <v-row justify="center" class="text-center">
    <v-col cols="12" md="8">
      <v-row justify="start" justify-sm="center" class="text-center">
        <v-col cols="10" md="4" class="pa-4">
          <v-card
            class="cards pa-6 text-left"
            color="transparent"
            elevation="0"
          >
            <v-card-actions>
              <svg
                width="47"
                height="47"
                viewBox="0 0 47 47"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="29.375"
                  cy="29.375"
                  r="5.875"
                  stroke="#4024EA"
                  stroke-width="4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M25.4584 34.2709V43.0834L29.375 40.1459L33.2917 43.0834V34.2709"
                  stroke="#4024EA"
                  stroke-width="4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M19.5833 37.2083H9.79167C7.62855 37.2083 5.875 35.4547 5.875 33.2916V13.7083C5.875 11.5541 7.6375 9.79163 9.79167 9.79163H37.2083C39.3714 9.79163 41.125 11.5452 41.125 13.7083V33.2916C41.1236 34.6895 40.3772 35.9806 39.1667 36.6795"
                  stroke="black"
                  stroke-width="4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11.75 17.625H35.25"
                  stroke="black"
                  stroke-width="4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11.75 23.5H17.625"
                  stroke="black"
                  stroke-width="4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11.75 29.375H15.6667"
                  stroke="black"
                  stroke-width="4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </v-card-actions>
            <div class="font1 pa-2 font_wrap title_card">
              Safe and<br/> efficient
            </div>
            <div class="font1 text_card pa-2 font_wrap">
              Specialist’s application is depersonalized, necessarily contains
              the real range of salary, professional characteristics.
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" md="4" class="pa-4">
          <v-card
            class="cards pa-6 text-left"
            color="transparent"
            elevation="0"
          >
            <v-card-actions>
              <svg
                width="47"
                height="47"
                viewBox="0 0 47 47"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="29.375"
                  cy="29.375"
                  r="7.83333"
                  stroke="#4024EA"
                  stroke-width="5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M36.2291 36.2291L41.125 41.125"
                  stroke="#4024EA"
                  stroke-width="5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.83337 11.75H39.1667"
                  stroke="black"
                  stroke-width="5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.83337 23.5H15.6667"
                  stroke="black"
                  stroke-width="5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.83337 35.25H15.6667"
                  stroke="black"
                  stroke-width="5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </v-card-actions>
            <div class="font1 pa-2 font_wrap title_card">
              Leave your application
            </div>
            <div class="font1 text_card pa-2 font_wrap">
              Leave your applications for faster research of proven workplaces
              or projects
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" md="4" class="pa-4">
          <v-card
            class="cards pa-6 text-left"
            color="transparent"
            elevation="0"
          >
            <v-card-actions>
              <svg
                width="47"
                height="47"
                viewBox="0 0 47 47"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="17.625"
                  cy="13.7083"
                  r="7.83333"
                  stroke="black"
                  stroke-width="5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M5.875 41.125V37.2083C5.875 32.8821 9.3821 29.375 13.7083 29.375H21.5417C25.8679 29.375 29.375 32.8821 29.375 37.2083V41.125"
                  stroke="black"
                  stroke-width="5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M31.3333 21.5417L35.25 25.4583L43.0833 17.625"
                  stroke="#4024EA"
                  stroke-width="5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </v-card-actions>
            <div class="font1 pa-2 font_wrap title_card">
              Secure<br/> applications
            </div>
            <div class="font1 text_card pa-2 font_wrap">
              All applications are checked by moderator. If 100% reliable, the
              moderator sets the badge.
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {};
</script>

<style scoped>
.title_card {
  font-size: 1.6rem;
}
.text_blue {
  color: #4024ea;
}
.text_card {
  font-size: 1.1rem;
  line-height: 1.6rem;
}
@media screen and (max-width: 400px) {
  .title_card {
    font-size: 1.2rem;
    line-height: 2rem;
  }
  .text_card {
    font-size: 0.85rem;
    line-height: 1.5rem;
    color: #565656;
  }
}
</style>