<template>
  <v-row justify="center" class="text-center">
    <v-col cols="11" md="9">
      <v-row justify="center" class="text-center mt-8 mb-sm-12">
        <div class="font2 d-none d-sm-flex">How to use it as a specialist</div>
        <div class="font2 d-sm-none">Start to use it as a specialist!</div>
      </v-row>
      <v-row justify="center" class="text-center pt-12">
        <v-card class="card_main" color="#fff">
          <v-row class="text-left ma-0 px-4 px-sm-6 px-md-15 py-10 row_1">
            <div class="font3 d-none d-sm-flex">
              Lite / from $2.99 per connection with employer / project
            </div>
            <div class="font3 d-sm-none">
              <v-card-title
                class="text-center justify-center ma-0 font4 ma-0 pa-0"
              >
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M23.7333 26.4227L20.8373 27.94C20.662 28.0312 20.4503 28.0158 20.2901 27.9002C20.1299 27.7845 20.0486 27.5884 20.08 27.3933L20.6333 24.1787L18.2906 21.9027C18.148 21.7647 18.0965 21.5575 18.158 21.3688C18.2196 21.1802 18.3834 21.0432 18.58 21.016L21.8173 20.5467L23.2653 17.6227C23.3536 17.445 23.5349 17.3327 23.7333 17.3327C23.9317 17.3327 24.113 17.445 24.2013 17.6227L25.6493 20.5467L28.8866 21.016C29.0826 21.0442 29.2455 21.1812 29.3069 21.3694C29.3683 21.5576 29.3176 21.7643 29.176 21.9027L26.8333 24.1787L27.3853 27.392C27.4191 27.5875 27.3387 27.7852 27.1781 27.9016C27.0175 28.018 26.8046 28.0329 26.6293 27.94L23.7333 26.4227V26.4227Z"
                    stroke="#4024EA"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.26663 26.4227L5.37062 27.94C5.19535 28.0312 4.98362 28.0158 4.82341 27.9002C4.6632 27.7845 4.5819 27.5884 4.61329 27.3933L5.16662 24.1787L2.82396 21.9027C2.68129 21.7647 2.62982 21.5575 2.69138 21.3688C2.75293 21.1802 2.91673 21.0432 3.11329 21.016L6.35063 20.5467L7.79863 17.6227C7.88696 17.445 8.06824 17.3327 8.26663 17.3327C8.46501 17.3327 8.64629 17.445 8.73463 17.6227L10.1826 20.5467L13.42 21.016C13.6159 21.0442 13.7789 21.1812 13.8403 21.3694C13.9017 21.5576 13.8509 21.7643 13.7093 21.9027L11.3666 24.1787L11.9186 27.392C11.9524 27.5875 11.872 27.7852 11.7114 27.9016C11.5508 28.018 11.3379 28.0329 11.1626 27.94L8.26663 26.4227V26.4227Z"
                    stroke="#4024EA"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M16 13.0893L13.104 14.6067C12.9288 14.6979 12.717 14.6825 12.5568 14.5668C12.3966 14.4512 12.3153 14.2551 12.3467 14.06L12.9 10.8453L10.5574 8.56934C10.4147 8.43142 10.3632 8.22418 10.4248 8.03554C10.4863 7.84689 10.6501 7.7099 10.8467 7.68267L14.084 7.21334L15.532 4.28934C15.6204 4.1117 15.8016 3.99939 16 3.99939C16.1984 3.99939 16.3797 4.1117 16.468 4.28934L17.916 7.21334L21.1534 7.68267C21.3493 7.71086 21.5122 7.84787 21.5737 8.03606C21.6351 8.22426 21.5843 8.431 21.4427 8.56934L19.1 10.8453L19.652 14.0587C19.6858 14.2542 19.6054 14.4519 19.4448 14.5683C19.2842 14.6847 19.0713 14.6996 18.896 14.6067L16 13.0893V13.0893Z"
                    stroke="#4024EA"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                Lite
              </v-card-title>
              <span>From $2.99 per confirmed job connection</span>
            </div>
            <div class="font1 text_blue">Free to try our features!</div>
          </v-row>
          <v-row justify="center" class="text-center my-4">
            <v-col cols="11" md="4" class="pb-0 pb-sm-3">
              <v-list color="transparent" class="mt-2 pb-0 pb-sm-2">
                <v-list-item v-for="(item, i) in items" :key="i">
                  <v-list-item-icon>
                    <svg
                      width="19"
                      height="14"
                      viewBox="0 0 19 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2 7L7 12L17 2"
                        stroke="#4024EA"
                        stroke-width="4"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      class="text-left font1 list_text"
                      v-text="item.text"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="11" md="4" class="pt-0 pt-sm-3">
              <v-list color="transparent" class="mt-sm-2 pt-0 pt-sm-2">
                <v-list-item v-for="(item, i) in items2" :key="i">
                  <v-list-item-icon>
                    <svg
                      width="19"
                      height="14"
                      viewBox="0 0 19 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2 7L7 12L17 2"
                        stroke="#4024EA"
                        stroke-width="4"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      class="text-left font1 list_text"
                      v-text="item.text"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
          <v-row justify="center" class="text-center ma-0 pa-6">
            <v-btn
              color="#4024EA"
              class="white--text px-16 texr_btn font1 mb-2"
              large
              elevation="0"
              >Start now for free!</v-btn
            >
          </v-row>
        </v-card>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "HowToUsePage",
  data: () => ({
    items: [
      { text: "Essential functionality " },
      { text: "New offers / jobs daily" },
      { text: "Recruiting / hiring consulting" },
      { text: "5 connections per week" },
    ],
    items2: [
      { text: "Teams " },
      { text: "In-built CV managment" },
      { text: "Recruiting / hiring consulting" },
      { text: "Rating of specialists" },
    ],
  }),
};
</script>

<style scoped>
.texr_btn {
  text-transform: none;
  letter-spacing: 0.0392857143em;
  border-radius: 10px;
}
.row_1 {
  background: linear-gradient(89.79deg, #cedcff 11.24%, #aea0ff 99.95%);
}
.text_blue {
  color: #4024ea;
}
.card_main {
  border-radius: 45px;
  background: #fff;
}
.font2 {
  font-family: "Poppins", sans-serif !important;
  font-weight: 700 !important;
  font-size: 2rem;
  line-height: 3rem;
  color: #2b2b2b;
}
.font3 {
  width: 100%;
  font-family: "Poppins", sans-serif !important;
  font-weight: 700 !important;
  font-size: 2rem;
  line-height: 3rem;
  color: #2b2b2b;
}
.font4 {
  width: 100%;
  font-family: "Poppins", sans-serif !important;
  font-weight: 700 !important;
  font-size: 2rem;
  line-height: 3rem;
  color: #2b2b2b;
}
@media screen and (max-width: 400px) {
  .font3 {
    font-size: 1rem;
    line-height: 1.8rem;
  }
  .font4 {
    font-size: 2rem;
    line-height: 2.8rem;
  }
  .list_text {
    font-size: 1rem;
  }
  .font2 {
    font-size: 1.2rem;
    line-height: 2rem;
    color: #4024ea;
  }
}
</style>