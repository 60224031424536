<template>
  <v-row justify="center" class="text-center">
    <v-col cols="12" md="9">
      <v-row justify="center" class="text-center mt-8 mb-12">
        <div class="font2">Our general partners</div>
      </v-row>
      <v-row justify="center" class="text-left">
        <v-col cols="6" sm="5" md="3" class="px-6 px-md-12">
            <v-img max-width="100%" contain :src="require('../../assets/HomeForDevelopers/p1.png')"></v-img>
        </v-col>
        <v-col cols="6" sm="5" md="3" class="px-6 px-md-12">
            <v-img max-width="100%" contain :src="require('../../assets/HomeForDevelopers/p2.png')"></v-img>
        </v-col>
        <v-col cols="6" sm="5" md="3" class="px-6 px-md-12">
            <v-img max-width="100%" contain :src="require('../../assets/HomeForDevelopers/p1.png')"></v-img>
        </v-col>
        <v-col cols="6" sm="5" md="3" class="px-6 px-md-12">
            <v-img max-width="100%" contain :src="require('../../assets/HomeForDevelopers/p3.png')"></v-img>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {};
</script>

<style scoped>
.font2 {
  font-family: "Poppins", sans-serif !important;
  font-weight: 700 !important;
  font-size: 2.2rem;
  line-height: 3.8rem;
  color: #2b2b2b;
}
@media screen and (max-width: 400px) {
  .font2 {
    font-size: 1.3rem;
    line-height: 3.8rem;
  }
}
</style>