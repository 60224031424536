<template>
  <v-row justify="center" class="text-center">
    <v-col cols="12" lg="10">
      <v-row justify="center" class="text-center mb-2 mb-sm-12">
        <div class="font2">About Us</div>
      </v-row>
      <v-row justify="center" class="text-center">
        <v-col cols="12" sm="6" lg="5">
          <div class="font3 Hi">Hi-base service</div>
          <hr class="hr_blue my-1 my-sm-4" />
          <div class="subtitle px-md-8 font1">
            “Hi-base is a team of IT specialists being in situations that were
            uncomfartable and unprofitable. We found the solution.”
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Hi-Base",
};
</script>
<style scoped>
.subtitle {
  color: #dbdaef;
  font-size: 1.1rem;
}
.hr_blue {
  height: 3px;
  background-color: rgba(46, 92, 255, 0.24);
  border: unset;
}
.Hi {
  color: #4024ea;
}
.font2 {
  font-family: "Poppins", sans-serif !important;
  font-weight: 700 !important;
  font-size: 2rem;
  line-height: 3rem;
}
.font3 {
  font-family: "Poppins", sans-serif !important;
  font-weight: 700 !important;
  font-size: 2.6rem;
  line-height: 3rem;
}
@media screen and (max-width: 400px) {
  .font2 {
    font-family: "Poppins", sans-serif !important;
    font-weight: 700 !important;
    font-size: 1.1rem;
    line-height: 3rem;
  }
  .font3 {
    font-family: "Poppins", sans-serif !important;
    font-weight: 700 !important;
    font-size: 1.2rem;
    line-height: 3rem;
  }
  .subtitle {
    color: #dbdaef;
    font-size: 0.9rem;
  }
}
</style>