<template>
  <v-row justify="center" class="text-center">
    <v-col cols="10" sm="5" lg="4">
      <v-card class="card_choose pa-6" flat>
        <div class="font2 text-center font_wrap">How to start</div>
        <div class="text_subtitle text-center font_wrap font1">
          Working with our service as
        </div>
        <v-card-actions class="justify-center mt-3 mt-sm-6">
          <v-btn
            class="btn_specialist font1 text-capitalize white--text px-4 px-sm-6"
            color="#4024EA"
            large
          >
            Specialist
          </v-btn>
          <v-btn
            class="btn_recruter font1 text-capitalize white--text px-4 px-sm-6"
            elevation="0"
            color="#F2518B"
            large
          >
            Recruiter
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Card-Choose",
};
</script>

<style scoped>
.btn_recruter {
  font-size: 1rem;
  letter-spacing: 0.0212857143em;
  border-radius: 10px;
}
.btn_specialist {
  font-size: 1rem;
  letter-spacing: 0.0212857143em;
  box-shadow: -10px 11px 50px -1px rgba(64, 36, 234, 0.71);
  border-radius: 10px;
}
.font2 {
  font-family: "Poppins", sans-serif !important;
  font-weight: 700 !important;
  font-size: 2rem;
  line-height: 3rem;
  color: #2b2b2b;
}
.text_subtitle {
  color: #565656;
}
.card_choose {
  background: rgba(231, 238, 245, 0.3);
  border-radius: 22px;
}
@media screen and (max-width: 400px) {
  .font2 {
    font-size: 1.3rem;
    line-height: 2rem;
  }
  .text_subtitle {
    font-size: 0.85rem;
  }
  .btn_specialist, .btn_recruter {
    font-size: 0.9rem;
  }
}
</style>