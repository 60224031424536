<template>
  <v-row justify="start" justify-sm="center" class="text-center">
    <v-col cols="6" sm="4" offset-md="1">
      <v-img
        aspect-ratio="1"
        contain
        max-width="100%"
        :src="require('../../assets/HomeForDevelopers/3.png')"
      ></v-img>
    </v-col>
    <v-col cols="12" sm="5" offset-md="1">
      <v-card color="transparent" flat>
        <v-card-title class="font2 text-left font_wrap pr-sm-6">
          Our team is ready<br />
          to help you right now!
        </v-card-title>
        <v-card-subtitle class="font1 text-left font_wrap d-sm-none">
          Our service will help you find a job or project as quickly as
          possible! With us you will find reliable offers in a short time
        </v-card-subtitle>
        <v-list color="transparent" class="mt-2">
          <v-list-item v-for="(item, i) in items" :key="i">
            <v-list-item-icon class="mr-2 mr-sm-8">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="12"
                  cy="12"
                  r="9"
                  stroke="#F2518B"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8.99988 9H9.00988"
                  stroke="#F2518B"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.9999 9H15.0099"
                  stroke="#F2518B"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M9 13C9 12.4477 8.55228 12 8 12C7.44772 12 7 12.4477 7 13H9ZM17 13C17 12.4477 16.5523 12 16 12C15.4477 12 15 12.4477 15 13H17ZM16 14C16.5523 14 17 13.5523 17 13C17 12.4477 16.5523 12 16 12V14ZM8 12C7.44772 12 7 12.4477 7 13C7 13.5523 7.44772 14 8 14V12ZM7 13C7 15.7614 9.23858 18 12 18V16C10.3431 16 9 14.6569 9 13H7ZM12 18C14.7614 18 17 15.7614 17 13H15C15 14.6569 13.6569 16 12 16V18ZM16 12H8V14H16V12Z"
                  fill="#F2518B"
                />
              </svg>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                class="text-left font1 list_text"
                v-text="item.text"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "SecondPage",
  data: () => ({
    items: [
      { text: "Find employees or team" },
      { text: "Have access to our refreshing data base" },
      { text: "Quickly and with high efficiency" },
      { text: "Partly control provided" },
    ],
  }),
};
</script>

<style scoped>
.list_text {
  color: #565656;
  font-size: 1.1rem;
}
.font2 {
  font-family: "Poppins", sans-serif !important;
  font-weight: 700 !important;
  font-size: 2rem;
  line-height: 3rem;
  color: #2b2b2b;
}
@media screen and (max-width: 400px) {
  .font2 {
    font-size: 1.1rem;
    line-height: 2rem;
  }
  .list_text {
    font-size: 0.8rem;
  }
}
</style>